<template>
	<div class="goods-list" v-loading="loading">
		<div class="search_bread">
			<span class="link" @click="search({ category_id:''})">全部</span>
			<span v-for="item in category.levels" :key="item.category_id">
				<span class="padd-10">/</span>
				<span class="link" @click="search({ category_id:item.category_id})">{{item.category_name}}</span>
			</span>
			<span class="tag" v-if="filters.keyword"  @click="search({keyword:''})"><span class="label">搜索</span>{{ filters.keyword }}</span>
			<span class="tag" v-if="filters.style_id && params.style_name" @click="search({style_id:'',style_name:''})"><span class="label">风格</span>{{ params.style_name }}</span>
			<span class="tag" v-if="filters.brand_id && params.brand_name" @click="search({brand_id:'',brand_name:''})"><span class="label">品牌</span>{{ params.brand_name }}</span>
			<span class="padd-10 reset" @click="reset">重置</span>
		</div>
		<div class="filter" v-if="category.child_list&&category.child_list.length">
			<p>分类：</p>
			<ul>
				<li v-for="item in category.child_list" :key="item.category_id"><span class="link" @click="search({ category_id: item.category_id})">{{item.category_name}}</span></li>
			</ul>
		</div>
		<div class="filter" v-if="styleList">
			<p>风格：</p>
			<ul>
				<li v-for="item in styleList" :key="item.style_id">
					<span class="link active" v-if="item.style_id==filters.style_id" @click="search({style_id:''})" effect="plain">
						{{ item.style_name }}
					</span>
					<span class="link" v-else @click="search({style_id:item.style_id})">
						{{ item.style_name }}
					</span>
				</li>
			</ul>
		</div>
		<div class="filter" v-if="brandList">
			<p>品牌：</p>
			<ul>
				<li v-for="item in brandList" :key="item.brand_id">
					<span class="link active" v-if="item.brand_id==filters.brand_id" @click="search({brand_id:''})" effect="plain">
						{{ item.brand_name }}
					</span>
					<span class="link" v-else @click="search({brand_id:item.brand_id})">
						{{ item.brand_name }}
					</span>
				</li>
			</ul>
		</div>
		<!-- 排序筛选区 -->
		<div>
			<div class="sort">
				<div :class="{item:true,active:!filters.order}" @click="changeSort('')">
					<div class="item-name">综合</div>
				</div>
				<div :class="{item:true,active:filters.order=='sale_num'}" @click="changeSort('sale_num')">
					<div class="item-name">销量</div>
					<i v-if="filters.order === 'sale_num' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
					<i v-else class="el-icon-arrow-up el-icon--up" />
				</div>
				<div :class="{item:true,active:filters.order=='discount_price'}" @click="changeSort('discount_price')">
					<div class="item-name">价格</div>
					<i v-if="filters.order === 'discount_price' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
					<i v-else class="el-icon-arrow-up el-icon--up" />
				</div>
				<!-- <div class="item-other">
					<el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
				</div> -->
				<!-- <div class="item-other">
					<el-checkbox label="自营" v-model="is_own">特价</el-checkbox>
				</div> -->
				<!-- <div class="item-other">
					<el-checkbox label="特价" :checked="!!filters.is_tejia" @change="changeTejia"><span style="color:#ff6600;font-size:16px;">特价</span></el-checkbox>
				</div> -->
				<div class="input-wrap">
					<div class="price_range">
						<el-input placeholder="最低价格" size="small" v-model="filters.min_price"></el-input>
						<span>—</span>
						<el-input placeholder="最高价格" size="small" v-model="filters.max_price"></el-input>
					</div>
					<el-button plain size="small" @click="handlePriceRange">确定</el-button>
				</div>
			</div>
		</div>
		<div id="goods_list_target" class="cargo-list" v-if="goodsList.length">
			<div class="goods-info">
				<div :class="{item:true,item_last:index%4==3}" v-for="(item, index) in goodsList" :key="item.goods_id" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
					<el-image fit="contain" class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" ></el-image>
					<div class="price-wrap">
						<div class="price">
							<p>￥</p>
							{{ item.discount_price }}
						</div>
						<div class="market-price">￥{{ item.market_price }}</div>
					</div>
					<div class="goods-name">{{ item.goods_name }}</div>
					<div class="sale-num">
						<p>{{ item.sale_num || 0 }}</p>人付款
					</div>
				</div>
			</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="page"
					:page-size.sync="pageSize"
					@current-change="handleCurrentPageChange"
					hide-on-single-page></el-pagination>
			</div>
		</div>
		<div class="empty" v-else>
			<div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
		</div>
	</div>
</template>

<script>
import { goodsSkuPage,styleList,brandList } from "@/api/goods/goods"
import { goodsCategoryInfo } from "@/api/goods/goodscategory"
export default {
	name: "list",
	data: () => {
		return {
			loading:true,
			total:0,
			pageSize:24,
			page:1,
			goodsList:[],
			styleList:[],
			brandList:[],
			category:{},
			filters:{
				max_price:"",
				min_price:"",
				sort:""
			},
			keyword:'',
			params:{},
		};
	},
	created() {
		for(let k in this.$route.query){
			this.filters[k] = this.$route.query[k];
		}
		this.getGoodsList();
		styleList().then(res=>{
			this.styleList = res.data
		})
		brandList().then(res=>{
			this.brandList = res.data
		})
	},
	methods: {
		getGoodsList(){
			const params = {
				page_size: this.pageSize,
				page:this.page,
				keyword:this.keyword,
                ...this.filters
            }
            goodsSkuPage(params || {})
                .then(res => {
                    const { count , list , params } = res.data
                    this.total = count
                    this.goodsList = list
                    this.loading = false
					this.params = params
                })
                .catch(err => {
                    this.loading = false
                })
			if( params.category_id > 0 ){ //指定分类
				goodsCategoryInfo({
					category_id: this.filters.category_id
				}).then( res=>{
					this.category = {
						level:res.data.level,
						category_id:res.data.category_id,
						child_list:res.data.child_list,
					};
					var levels = [];
					var category_full_name = res.data.category_full_name.split("/");
					category_full_name.forEach( (item,idx)=>{
						levels.push({
							category_id:res.data["category_id_"+(1+idx)],
							category_name:item,
							level:1+idx
						})
					} )
					this.category.levels = levels;
				} )
			}else{
				this.category = {
					child_list:[
						{
							category_name:'家具',
							category_id:1,
							level:1
						},
						{
							category_name:'软装',
							category_id:2,
							level:1
						},
						{
							category_name:'灯饰',
							category_id:3,
							level:1
						}
					]
				}
			}
		},
		reset(){
			this.filters = {};
			this.search({})
		},
		search( params ){
			for(let k in params){
				this.filters[k] = params[k];
			}
			var query = {};
			for( let k in this.filters ){
				if( this.filters[k] !== '' && this.filters[k] !== undefined ){
					query[k] = this.filters[k]
				}
			}
			this.page=1;
			this.$router.push({ path: '/list', query })
			this.getGoodsList();
		},
		handleCurrentPageChange(page){
			document.getElementById("goods_list_target").scrollIntoView({ behavior: "smooth" });
			this.page = page;
			this.getGoodsList();
		},
		changeSort(type) {
			var params = {};
            if (this.filters.order === type) {
				params.sort = this.filters.sort === "desc" ? "asc" : "desc"
            } else {
				params.order = type
				params.sort = "desc"
            }
            this.search(params)
        },
		handlePriceRange(){
			this.search({})
		},
	},
	watch:{
		$route(curr) {
			if( curr.query.keyword != this.filters.keyword ){
				this.search({
					keyword:curr.query.keyword
				});
			}
		},
	}
};
</script>

<style lang="scss" scoped>
	.active{
		color:#f60
	}
	.link{
		cursor: pointer;
		&:hover{
			color:#f60
		}
	}
	.padd-10{
		padding: 0 10px;
	}
	.search_bread{
		line-height:24px;
		font-size:14px;
		padding:10px;
		margin-top:10px;
		.reset{
			color:#999;
			cursor: pointer;
			&:hover{
				color:#f60;
			}
		}
		.tag{
			display:inline-block;
			margin-left:16px;
			border:1px solid #f60;
			padding-left:6px;
			line-height:24px;
			color:#f60;
			border-radius: 4px;
			cursor: pointer;
			.label{
				display:inline-block;
				color:#333;
				&::after{
					content: ":";
				}
			}
			&::after{
				content:"×";
				display:inline-block;
				width:20px;
				text-align: center;
				color:#fff;
				margin-left:4px;
				background: #f60;;
			}
		}
	}
	.filter {
		display: inline-block;
		font-size: 14px;
		margin: 0px auto;
		width: 100%;
		padding: 10px;
		p{
			float: left;
			font-weight:bold;
		}
		li{
			float: left;
			padding: 0 10px;
		}
		.active a{
			color:#ff547b !important;
		}
		.tag{
			margin-left:10px;
		}
	}
	.goods-info {
		margin-top: 5px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.item {
			width: 320px;
			margin-top:18px;
			margin-right:17px;
			border: 1px solid #eeeeee;
			position: relative;
			padding-bottom: 10px;

			&:nth-child(4n) {
				margin-right: 0 !important;
			}

			&:hover {
				border: 1px solid $base-color;
			}

			.img-wrap {
				width: 298px;
				height: 298px;
				cursor: pointer;
				padding: 10px;
			}

			.goods-name {
				padding: 0 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
				height: 50px;
				line-height: 24px;
				cursor: pointer;

				&:hover {
					color: $base-color;
				}
			}

			.price-wrap {
				padding: 0 10px;
				display: flex;
				align-items: center;

				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;

					p {
						font-size: $ns-font-size-base;
						display: flex;
						align-items: flex-end;
					}
				}

				.market-price {
					color: #838383;
					text-decoration: line-through;
					margin-left: 10px;
				}
			}

			.sale-num {
				padding: 0 10px;
				display: flex;
				color: #838383;
				font-size: $ns-font-size-sm;

				p {
					color: #4759a8;
				}
			}

			.shop_name {
				padding: 0 10px;
				display: flex;
				color: #838383;
			}

			.saling {
				padding: 0 10px;
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;

				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}

				.is_own {
					color: #ffffff;
					background: #ff850f;
					border: 1px solid;
					margin-right: 5px;
					display: flex;
					align-items: center;
					padding: 3px 4px;
					border-radius: 2px;
				}

				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px 3px;
				}
			}

			.item-bottom {
				display: flex;
				margin-top: 5px;

				.collection {
					flex: 1;
					border: 1px solid #e9e9e9;
					border-right: none;
					border-left: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}

				.cart {
					flex: 2;
					border: 1px solid #e9e9e9;
					border-right: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}
	.sort {
		display: flex;
		align-items: center;
		line-height: 28px;
		.item {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;

			&:hover,&.active {
				background: $base-color;
				color: #fff;
			}
		}

		.item-other {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;
		}

		.input-wrap {
			display: flex;
			align-items: center;

			.price_range {
				margin-left: 60px;
			}

			span {
				padding-left: 10px;
			}

			.el-input {
				width: 150px;
				margin-left: 10px;
			}

			.el-button {
				margin: 0 17px;
			}
		}

		>div:first-child {
			border-left: 1px solid #f1f1f1;
		}
	}
	.empty {
		margin-top: 45px;
	}

	.pager {
		text-align: center;
		margin-top: 30px;
	}
</style>
